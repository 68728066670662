import './about.css'
import IMG from '../../assets/about-me.jpg'
import {FaAward} from "react-icons/fa";
import {VscFolderLibrary} from "react-icons/vsc";
import {CgWorkAlt} from "react-icons/cg";
import Passions from "./Passions";
const About = () => {
  return (
      <section id="about">
          <h2>A propos</h2>
          <p>
              Je suis Lucas Fabry, un ingénieur en génie logiciel passionné par la création d'applications web et de bureau.
              Mon expertise se concentre principalement sur Java et PHP (Symfony), bien que je sois constamment ouvert à l'apprentissage
              de nouvelles technologies et langages. Diplômé de Polytech Annecy-Chambéry et titulaire d'une maîtrise de l'Université de Québec à Chicoutimi,
              je suis déterminé à repousser les frontières du génie logiciel. Outre ma passion pour le développement, je suis également fortement intéressé
              par les techniques DevOps et la mise en place de CI/CD. Mon objectif est de créer des logiciels de qualité tout en restant à l'avant-garde de l'industrie.
          </p>
          <div className="container about__container">
              <div className="about__me">
                  <div className="about__me-image">
                      <img src={IMG} alt="Lucas Fabry, souriant de face au Lac Louise"/>
                  </div>
              </div>
              <div className="about__content">
                  <div className="about__cards">
                      <article className="about__card">
                          <FaAward className="about__icon"/>
                          <h5>Diplôme</h5>
                          <small>Ingénieur Informatique</small>
                      </article>

                      <article className="about__card">
                          <CgWorkAlt className="about__icon"/>
                          <h5>Expérience</h5>
                          <small>Junior</small>
                      </article>

                      <article className="about__card">
                          <VscFolderLibrary className="about__icon"/>
                          <h5>Expertise</h5>
                          <small>Génie Logiciel</small>
                      </article>
                  </div>
                  <h3>Développeur & Concepteur & Ingénierie logicielle.</h3>
                  <p>
                      Mon intérêt pour la conception et le développement de logiciels et d'applications web provient de ma passion pour la technologie
                      et mon désir de créer des solutions innovantes qui améliorent l'expérience des utilisateurs. C'est un domaine qui offre une infinité
                      de possibilités créatives et qui me permet de relever des défis stimulants au quotidien.
                  </p>

                  <a href="#contactsection" class="btn btn-primary">Parlons-nous !</a>
              </div>
          </div>
      <Passions />
      </section>
  )
}

export default About