import React, { useState } from 'react';

class SkillsSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSkill: null,
        };

        this.skillCategories = {
            langages: {title: "Langages de programmation", skills: ['JAVASCRIPT', 'PYTHON', 'JAVA', 'C#', 'PHP']},
            typeOfProjects : {title: "Types de projets", skills: ['WEB', 'JEUX-VIDÉO', 'IA', 'MOBILE', 'LOGICIEL']},
            frameworks: {title: "Frameworks", skills: ['REACT', 'BOOTSTRAP', 'JAVA SWING', 'SYMFONY']},
        };
    }

    handleSkillClick = (skill) => {
        const { selectedSkill } = this.state;
        const { projects, setFilteredProjects } = this.props;

        if (selectedSkill === skill) {
            this.setState({ selectedSkill: null });
            setFilteredProjects(projects);
        } else {
            this.setState({ selectedSkill: skill });
            const filtered = projects.filter((project) =>
                project.skills.includes(skill)
            );
            setFilteredProjects(filtered);
        }
    };

    render() {
        const { selectedSkill } = this.state;

        return (
            <div className="skill-manager">
                <div className="skill-categories">
                    {Object.keys(this.skillCategories).map((categoryKey, index) => {
                        const category = this.skillCategories[categoryKey];
                        return (
                            <div key={index} className="skill-category">
                                <h3>{category.title}</h3>
                                {category.skills.map((skill, skillIndex) => (
                                    <span
                                        key={skillIndex}
                                        className={`skill-pill ${selectedSkill === skill ? 'active' : ''}`}
                                        data-skill={skill.toUpperCase()}
                                        onClick={() => this.handleSkillClick(skill)}
                                    >
                      {skill}
                    </span>
                                ))}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default SkillsSelector;
