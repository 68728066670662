import CV from '../../assets/CV_Lucas_Fabry.pdf'

const CTA = () => {
    return (
        <div className="cta">
            <a href={CV} download className='btn'>Download CV</a>
            <a href="#contat" className='btn-primary'>Contactez-moi</a>
        </div>
    )
}

export default CTA