// Parcours.js

import React from 'react';
import { Chrono } from 'react-chrono';
import './parcours.css';
import {FaLink} from "react-icons/fa6";

const Parcours = () => {
    const itemsGauche = [
        {
            title: '2018-2020',
            cardSubtitle: 'DUT Informatique',
            cardDetailedText: (
                <div className="timeline-detailed-text">
                    <p class="title-parcours-card">DUT Informatique</p>
                    <p class="parcours-date">> IUT Informatique Montpellier-Sète  </p>
                    <p class="description-parcours-card">J'ai commencé mes études par un &nbsp;
                        <a className="parcours-link"
                           href="https://iut-montpellier-sete.edu.umontpellier.fr/formations/bachelor-universaire-de-technologie/b-u-t-informatique/"
                           target="_blank"
                           rel="noreferrer"
                        >
                            <FaLink />
                            &nbsp; DUT informatique
                        </a>
                        &nbsp; à l'IUT de Montpellier-Sète,
                        acquérant des compétences solides en informatique qui ont grandement contribué à la suite de mon parcours.</p>
                </div>
            ),
        },
        {
            title: '2020-2024',
            cardSubtitle: 'Ingénieur Informatique',
            cardDetailedText: (
                <div className="timeline-detailed-text">
                    <p class="title-parcours-card">Ingénieur Informatique</p>
                    <p class="parcours-date">> Polytech Annecy-Chambéry</p>
                    <p class="description-parcours-card">
                        J'ai consolidé mes compétences en obtenant un diplôme &nbsp;
                        <a class="parcours-link"
                           href="https://www.polytech.univ-smb.fr/formation/systemes-numeriques-instrumentation/ingenieur-systemes-numeriques-instrumentation.html"
                           target="_blank"
                           rel="noreferrer"
                        >
                            <FaLink />
                            d'ingénieur en systèmes numériques et instrumentation
                        </a>
                        &nbsp;  me dotant ainsi d'une solide formation en ingénierie et me permettant de m'ouvir aux différents champs d'ingénierie</p>
                </div>
            ),
        },
        {
            title: '2022-2024',
            cardSubtitle: 'Maîtrise informatique',
            cardDetailedText: (
                <div className="timeline-detailed-text">
                    <p className="title-parcours-card">Maîtrise en Informatique</p>
                    <p className="parcours-date">> Université du Québec à Chicoutimi</p>
                    <p className="description-parcours-card">
                        J'ai enrichi mon parcours en obtenant un double diplôme en &nbsp;
                        <a className="parcours-link"
                           href="https://www.uqac.ca/programme/3037-maitrise-en-informatique/"
                           target="_blank"
                           rel="noreferrer"
                        >
                        <FaLink />
                        &nbsp; maîtrise informatique
                        </a> à l'Université de Québec à Chicoutimi , qui a renforcé mes connaissances et m'a ouvert à de
                        nouvelles perspectives.
                    </p>
                </div>
            ),
        }
    ];

    const itemsDroite = [
        {
            title: 'Étés 2018/2019/2020/2021',
            cardSubtitle: 'Technicien Informatique',
            cardDetailedText: (
                <div className="timeline-detailed-text">
                    <p className="title-parcours-card">Technicien Informatique</p>
                    <p className="parcours-date">> Communauté de Communes Région Lézignanaise-Minervois</p>
                    <p className="description-parcours-card">
                        En tant que technicien informatique pour une collectivité locale, j'ai rempli diverses missions, notamment l'installation
                        de câbles réseau pour étendre l'accès Internet à une école, le déploiement réussi de WebDelib et d'un serveur mail, ainsi
                        que la fourniture d'une assistance logicielle aux employés. Mon rôle a impliqué le renforcement des compétences en infrastructure,
                        en réseaux et en support technique.
                    </p>
                </div>
            ),
        },
        {
            title: 'Mai 2022-Juillet 2022',
            cardSubtitle: 'Développeur logiciel',
            cardDetailedText: (
                <div className="timeline-detailed-text">
                    <p className="title-parcours-card">Développeur logiciel</p>
                    <p className="parcours-date">> EasyAccesEnglish Montpellier</p>
                    <p className="description-parcours-card">
                        En tant que stagiaire technique j'ai conçu et déployé une plateforme de visioconférence sur une architecture Symfony, intégrant la technologie webRTC.
                        Responsable de l'ensemble du processus, de la conception de l'interface à la réalisation technique, assurant un déploiement réussi.
                    </p>
                </div>
            ),
        },
        {
            title: 'Septembre 2023 - Mars 2024',
            cardSubtitle: 'Développeur logiciel',
            cardDetailedText: (
                <div className="timeline-detailed-text">
                    <p className="title-parcours-card">Tech-Leader Add-On Blender</p>
                    <p className="parcours-date">> Alten SO</p>
                    <p className="description-carcours-card">
                        En tant que stagiaire ingénieur, j'ai réalisé un add-on sur Blender permettant de piloter un jumeau numérique d'une maquette d'usine.
                        Réalisé en python, l'add-on Blender implémente une architecture de communication MQTT et manipule plusieurs centaines de données
                        à une haute fréquence.
                    </p>
                </div>
            )
        }
    ];

    return (
        <section id="parcours" className="container">
            <h2>Parcours</h2>
            <p id="subtext">Bienvenue dans ma section Parcours Scolaire et Professionnel, où je vous invite à découvrir mon cheminement académique
                et mes expériences professionnelles. De mon diplôme de DUT informatique à l'IUT de Montpellier-Sète jusqu'à mon double diplôme
                en maîtrise informatique à l'Université de Québec à Chicoutimi, en passant par mon diplôme d'ingénieur en systèmes numériques
                et instrumentation de Polytech Annecy-Chambéry, chaque étape de mon parcours m'a permis d'acquérir des compétences solides et de
                développer ma passion pour le développement d'applications et les technologies numériques</p>
            <div className="parcours-container">
                <div className="timeline-container">
                    <h2>Parcours Scolaire</h2>
                    <div className="timeline-scrollable">
                        <Chrono
                            items={itemsGauche}
                            mode="VERTICAL_ALTERNATING"
                            theme={{
                                primary: '#66CC99',
                                secondary: '#48A07A',
                                cardBgColor: 'rgba(147, 190, 153, 1)',
                                cardForeColor: '#66CC99',
                                titleColor: '#66CC99',
                                titleColorActive: '#030A06FF',
                                iconBackgroundColor: '#66CC99'
                            }}

                        />
                    </div>
                </div>
                <div className="timeline-container">
                    <h2>Parcours Professionnel</h2>
                    <div className="timeline-scrollable">
                        <Chrono
                            items={itemsDroite}
                            mode="VERTICAL_ALTERNATING"
                            theme={{
                                primary: '#66CC99',
                                secondary: '#48A07A',
                                cardBgColor: 'rgba(147, 190, 153, 1)',
                                cardForeColor: '#66CC99',
                                titleColor: '#66CC99',
                                titleColorActive: '#030A06FF',
                                iconBackgroundColor: '#66CC99'
                            }}

                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Parcours;