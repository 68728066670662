import Header from './components/header/Header'
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import Portfolio from "./components/portfolio/Portfolio";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Parcours from "./components/parcours/Parcours";

const App = () => {
    return (
        <>
            <Header />
            <Nav />
            <About />
            <Parcours />
            <Portfolio />
            <Contact />
            <Footer />
        </>
    )
}

export default App