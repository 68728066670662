import './nav.css'
import {AiOutlineFundProjectionScreen, AiOutlineHome, AiOutlineUser} from "react-icons/ai";
import {BiMessageSquareDetail} from "react-icons/bi";
import {useState} from "react";
import {PiPathBold} from "react-icons/pi";

const Nav = () => {
    const [activeNav, setActiveNav] = useState('#')
    return (
        <nav>
            <a href="#" onClick={() => setActiveNav('#')} className= {activeNav === '#' ? 'active' : ''}><AiOutlineHome /></a>
            <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><AiOutlineUser /></a>
            <a href="#parcours" onClick={() => setActiveNav('#parcours')} className={activeNav === '#parcours' ? 'active' : ''}><PiPathBold /></a>
            <a href="#portfolio" onClick={() => setActiveNav('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}><AiOutlineFundProjectionScreen /></a>
            <a href="#contactsection" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><BiMessageSquareDetail /></a>
        </nav>
    )
}

export default Nav
