const Passions = () => {
    const passionsCards = [
        {
            title: "Trail running",
            description: "J'aime pratiqué les sports de montagne (escalade, randonnée, ski, etc.) et plus particulièrement le trail running. Je pratique l'athlétisme depuis " +
                "10 ans et je souhaite me porter dans les années prochaines années sur le trail court (30 à 50km).",
            actualite: "Préparation du Trail du Ventoux 2024"
        },
        {
            title: "Histoire",
            description: "Je suis particulièrement interessé par l'histoire. Notamment par la période couvrant le Grand Siècle de 1643 à 1815. Au-delà de l'histoire je" +
                "cherche dans le classicisme propre à cette période une source d'inspiration pour mon travail de développeur.",
            actualite: "Lecture de la biographie de Louis XIV par Pierre Gaxotte"
        },
        {
            title: "Informatique",
            description: "Je suis passioné par le monde de l'informatique et les possibilités qu'il offre. Je cherche constamment à progresser dans chaque domaine et à comprendre" +
                " les avancées importantes qui y sont liées. Je tiens d'ailleurs une veille active sur le site daily.dev",
            actualite: "Migration de Fedora vers QubesOS"
        }

        ]
    return(
        <div class="container passion-container" >
        <h2>Mes Passions</h2>
            <div className="ag-format-container">
                <div class="ag-courses_box">
                    {
                        passionsCards.map(({ title, description, actualite }) => (
                            <div class="ag-courses_item">
                                <a href="#" class="ag-courses-item_link">
                                    <div class="ag-courses-item_bg"></div>

                                    <div class="ag-courses-item_title">
                                        {title}
                                    </div>
                                    <div class="ag-courses-item_description"> {description}</div>
                                    <div class="ag-courses-item_date-box">
                                        En cours :
                                        <span class="ag-courses-item_date"> {actualite}</span>
                                    </div>
                                </a>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
        )
}

export default Passions;