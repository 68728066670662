import './footer.css'

const Footer = () => {
    return (
        <footer>
            <div class="container">
                <h3>Lucas FABRY</h3>
                <figure class="text-center">
                    <blockquote class="blockquote">
                        <p>Mais la plus grande douceur est d'occuper les hauts lieux fortifiés par la pensée des sages,
                            ces régions sereines d’où
                            s’aperçoit au loin le reste des hommes, qui errent çà et là en cherchant le chemin de la vie… </p>
                    </blockquote>
                    <figcaption class="blockquote-footer">
                        Lucrèce <cite title="Source Title"><span class="text-success-emphasis">De rerum natura</span>, livre II, v.7 sqq.</cite>
                    </figcaption>
                </figure>
            </div>
        </footer>

    )
}

export default Footer