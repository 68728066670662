import './contact.css'

const Contact = () => {
    return (
        <section id="contactsection">
            <h2>Contactez-Moi</h2>

            <div className="container contact__container">
                <form action="">
                    <input type="text" name="name" placeholder="Votre nom complet" required/>
                    <input type="email" name="email" placeholder="Votre adresse mail" required/>
                    <textarea name="message" rows="7" placeholder="Votre message" required></textarea>
                    <button type="submit" className="btn btn-primary">Envoyer</button>
                </form>
            </div>
        </section>
    )
}

export default Contact