import './header.css'
import CTA from './CTA'
import ME from '../../assets/me.webp'


const Header = () => {
  return (
      <header>
        <div className="container header__container">
            <h1>Lucas Fabry</h1>
            <h2 className="text-light">Ingénieur en génie logiciel</h2>
            <CTA />

            <div className="me">
                <img src={ME} alt="Lucas Fabry, souriant et levant les pouces" />
            </div>

            <a href="#contact" id="contact" className="scroll__down">
                Aller en bas
            </a>

        </div>
      </header>
  )
}

export default Header