import './portfolio.css'

import ImgQuoridor from '../../assets/projectsImg/quoridor.png'
import ImgEtsil from '../../assets/projectsImg/etsil.png'
import ImgTwitterAnalysis from '../../assets/projectsImg/twitterAnalysis.png'
import ImgDigitalTwin from '../../assets/projectsImg/digitalTwin.png'
import ImgVisioConference from '../../assets/projectsImg/visioconference.png'
import ImgWikiversite from '../../assets/projectsImg/wikiversite.png'
import ImgSocialEvent from '../../assets/projectsImg/socialEvent.png'
import ImgAmalgames from '../../assets/projectsImg/amalgames.png'
import ImgEBavards from '../../assets/projectsImg/eBavards.png'
import ImgPortfolio from '../../assets/projectsImg/portfolio.png'

import SkillsSelector from "./SkillsSelector";
import {useState} from "react";
import {BsGit} from "react-icons/bs";

const data = [
    {
        id: 1,
        image: ImgQuoridor,
        title: 'Quoridor',
        github: "https://framagit.org/Karpauh/quoridors3",
        description: "Implémentation complète d'un jeu Quoridor avec JavaFX et étude de la mise en place d'une interface web avec les servlets. " +
            "Réalisation d'une intelligence artificielle ayant un algorithme MINIMAX l'ensemble du développement respecte pluisieurs patrons de conception",
        skills: ["JAVA", "IA", "CONCEPTION", "UX/UI", "POO"]
    },
    {
        id: 2,
        image: ImgEtsil,
        title: 'ETSIL',
        github: "https://github.com/LucasFabry/siteAPP",
        description: "Au sein d'une équipe de 8 élèves ingénieurs développement d'une solution d'authentification des étudiants par RFID. Responsable de la partir" +
            "web app développée en Symfony 6 comprenant l'ensemble des opérations CRUD, un système d'authentification et un système de signalement et justification d'absence",
        skills: ["PHP", "SYMFONY", "BOOTSTRAP", "CONCEPTION", "UX/UI", "POO", "WEB"]
    },
    {
        id: 3,
        image: ImgTwitterAnalysis,
        title: 'Twitter Analysis',
        github: "https://github.com/louiselize/TwitterAnalysis",
        description: "L'objectif est de développer un agent intelligent capable de prédire la viralité d'un tweet lié au conflit russo-ukrainien. Pour ce faire j'ai scrappé des données à l'aide de la bibliothèque" +
            " snscrape et constituer un dataset représentatif ensuite j'ai entrainé une IA de type LLM",
        skills: ["PYTHON", "IA", "SNSCRAPE"]
    },
    {
        id: 4,
        image: ImgDigitalTwin,
        title: 'Blender Digital-Twin',
        description: "Réalisation en tant que Tech-Leader au sein d'une grande ESN d'un add-on blender pour piloter à distance une mini-usine. Le jumeau numérique est la liasion de" +
            " 13 projets différents et il doit manipuler un très grand nombre de données (plusieurs centaines de données envoyées par secondes)",
        skills: ["PYTHON", "BLENDER", "MQTT", "CONCEPTION", "UX/UI", "POO", "MODELISATION", "LOGICIEL"],
        inProgress: true
    },
    {
        id: 5,
        image: ImgVisioConference,
        title: 'Plateforme de visioconférence ',
        description: 'Réalisation pour une association d\'apprentissage de l\'anglais d\'une plateforme de visioconférence permettant de suivre les cours depuis son domicile. ' +
            ' La plateforme utilise le protocole d\'échange WebRTC et l\'interface est rendu dynamique par du JS Vanilla. J\'ai réalisé le projet complètement de la conception de ' +
            'l\'interface à son déploiement sur un site wordpress',
        skills: ["JAVASCRIPT", "WEBRTC", "UX/UI", "CONCEPTION", "TEMPS RÉEL", "WEB"]
    },
    {
        id: 6,
        image: ImgWikiversite,
        title: "Wikiversité",
        description: "Développement en Java Swing d'une solution d'échange de cours entre étuidants dans le but d'apprendre plusieurs design patterns liés à la conception " +
            "en Java Swing. L'objectif étant d'obtenir une application modulable et facilement extensible",
        github: "https://github.com/LucasFabry/wikiversite",
        skills: ["JAVA", "JAVA SWING", "UI/UX", "CONCEPTION", "POO", "LOGICIEL"]
    },
    {
        id: 7,
        image: ImgSocialEvent,
        title: "Social Event",
        description: "Développement d'une application mobile permettant de gérer son emploi du temps et de partager les événements entre utilisateurs.",
        github: "https://github.com/VincentBlanco/SocialEvent",
        skills: ["JAVA", "ANDROID", "MOBILE APP", "UX/UI", "MOBILE"]
    },
    {
        id: 8,
        image: ImgAmalgames,
        title: "Amalgames",
        description: "Réalisation d'un jeu vidéo de type Plateformer 2D sur Unity3D en utilisant C#.",
        github: "https://github.com/LeDETRAQUE/AmalGames",
        skills: ["C#", "UNITY3D", "MODELISATION", "JEUX-VIDÉO"]
    },
    {
        id: 9,
        image: ImgEBavards,
        title: "EBavards",
        description: "Réalisation d'un chat textuel en local avec une interface utilisateur en Java Swing afin d'utiliser les sockets et de se familiariser avec le développement" +
            " orienté objet et designs patterns",
        github: "https://github.com/LucasFabry/eBavards",
        skills: ["JAVA", "JAVA SWING", "UX/UI", "CONCEPTION", "POO", "TEMPS RÉEL", "LOGICIEL"]
    },
    {
        id: 10,
        image: ImgPortfolio,
        title: "Site Personnel",
        description: "Réalisation d'un portfolio blog, pour expérimenter de nouvelles technologies et de nouvelles pratiques de développement." +
            "L'objectif est de maitriser le CI/CD avec GitLab, construire une API Rest et monter en compétence sur ReactNative",
        github: "",
        skills: ["SYMFONY", "REACT", "REACT NATIVE", "CI/CD", "API REST", "BDD"]
    }
]
const Portfolio = () => {
    const [filteredProjects, setFilteredProjects] = useState(data);

    return (
        <div>


            <section id="portfolio">
                <h2>Portfolio</h2>
                <h5>Filtrer les projets par compétences : </h5>
                <SkillsSelector
                    skills={getUniqueSkills(data)}
                    projects={data}
                    setFilteredProjects={setFilteredProjects}
                />
                <div className="container portfolio__container">
                    {
                        filteredProjects.map(({ id, image, title, github, description, skills, inProgress }) => (
                            <article key={id} className={`portfolio__item ${inProgress ? 'enCours' : ''}`} >
                                <div className="portfolio__item-image">
                                    <img src={image} alt={title} />

                                </div>
                                <div className="overlay">
                                    <p className="project-description"><h5>{title}</h5>{description}</p>
                                </div>
                                <h3>{title}</h3>
                                {github && (
                                    <div className="portfolio__item-cta">
                                        <a href={github} className="btn" target="_blank" rel="noreferrer"><BsGit/> Repértoire Git</a>
                                    </div>
                                )}
                                <div className="skills">
                                    {skills.map((skill, index) => (
                                        <span className="skill" key={index}>{skill.toUpperCase()}</span>
                                    ))}
                                </div>
                            </article>
                        ))

                    }
                </div>
            </section>
        </div>
    )
}


const getUniqueSkills = (projects) => {
    let uniqueSkills = [];
    projects.forEach((project) => {
        project.skills.forEach((skill) => {
            if (!uniqueSkills.includes(skill)) {
                uniqueSkills.push(skill.toUpperCase());
            }
        });
    });
    return uniqueSkills;
};

export default Portfolio